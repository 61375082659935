/* swiper slider header carousel */

:root {
  --font-family-swiper: "Dancing Script", serif;
  --font-family-swiper: "Ranga", serif;
  --font-family-swiper: "Rancho";
  --font-family-swiper: "Atma", serif;
  --font-family-swiper: "Merienda", serif;
  --color-swiper-links: var(--color-links);
}

.swiper {
  max-width: 500px;
  padding-bottom: 0;
  margin: 0 auto;

  --swiper-navigation-color: var(--color-active-link);
  --swiper-navigation-color-hover: var(--color-active-hover);

}

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 500px;
    height: 325px;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }

    &.swiper-slide-duplicate {
       // background-image: none !important;
    }

    a {
      position: absolute;
      text-decoration: none;
      bottom: -5px;
      left: 0;
      right: 0;
      letter-spacing: -1px;

    }
    text-shadow: 6px 6px 10px rgba(0,0,0,0.2);

    .slide-title {
      color: var(--color-swiper-links);
      &:hover {
        color: var(--color-active-link);
      }
    }
    &.swiper-slide-active {
      text-shadow: 6px 6px 10px rgba(0,0,0,0.2);
      a {
        bottom: 20px;
      }
      .slide-title {
        font-size: 2rem;
        font-family: var(--font-family-swiper), sans-serif;
      }
    }
  }

  .swiper-slide[data-hash="zelte"].swiper-slide  {
    background-image: url('../Images/zelte.tag.png');
  }
  .swiper-slide[data-hash="alphuettli"].swiper-slide  {
    background-image: url('../Images/alphuettli.tag.png');
  }

  .swiper-slide[data-hash="eventwagen"].swiper-slide  {
    background-image: url('../Images/grotto.tag.png');
  }

  .swiper-slide[data-hash="zubehoer"].swiper-slide  {
    background-image: url('../Images/zubehoer.tag.png');
  }

  .swiper-button-prev, .swiper-button-next {
    //left: -20px;
    color: var(--swiper-navigation-color);
    &:hover {
      color: var(--swiper-navigation-color-hover)
    }
  }
  .swiper-button-next {
    //right: -20px
    color: var(--swiper-navigation-color)
  }

// todo: also hide as background image!
// hack to hide slides outside slider wrapper
.swiper-slide {
  &:not(.swiper-slide-prev),&:not(.swiper-slide-next),&:not(.swiper-slide-active) {
    background-image: none;
  }
  &.swiper-slide-duplicate {
    //    background-image: none;
    a {
        //color: red;
    }
  }
}

// increase font-size to compensate scaling by rotation
// set cursor to non interactive state, since clicking on image does not trigger navigation
// https://cssgenerator.org/text-shadow-css-generator.html

.swiper-slide.swiper-slide-prev, .swiper-slide.swiper-slide-next {
  font-size: 3.0rem; // compensate swiper scale effect
  cursor: initial;
  .slide-title {
    font-family: var(--font-family-swiper), sans-serif;
    //color: var(--color-prev-next-link);
  }
}

  .swiper-pagination {
    padding-top: 30px !important;
  }

  .swiper-3d {
    --overlay-color: var(--color-bg-header);
    --overlay-mode: multiply;
  }

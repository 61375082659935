// set grid breakpoints localy since we are not importing scss files in Main.scss for performance reasons
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;
@import "bootstrap/scss/mixins/breakpoints";

.fs-7 {
  font-size: 0.8rem !important;
}

.navbar {
  //--bs-navbar-active-color: var(--color-bg-header);
}
:root {
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;

  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-orange);
  --bs-btn-hover-border-color: var(--color-orange);
  //--bs-btn-focus-shadow-rgb: 49,132,253;

  // todo: change for light & dark mode
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);

  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

  /* increase column gap on image/text content elements */
.content-section .container .row {
  // todo: eventuell einschränken auf eine klasse wie .element-text-media ergänzen
  //--bs-gutter-x: 4rem;
}

/** expanded dropdown */
.navbar-nav .nav-link {
  --bs-navbar-active-color: var(--color-bg-header);
  --bs-nav-link-color: var(--color-header-link);
  &:focus {
    color: inherit;
  }
  &.show {
    color: #000000;
  }
  &.active {
    color: var(--color-header-link-active);
    position: relative;
    &:after {
      content: " ";
      display: block;
      width: 100%;
      height: 6px;
      background-color: var(--color-active-bar);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &:hover {
    color: var(--color-header-link-hover);
  }
}

.dropdown {
  &:has(.show) {
    background-color: #ffffff;
    .nav-link.dropdown-toggle {
      color: var(--color-bg-footer);
    }
  }
  ul.dropdown-menu {
    width: 100vw;
    border-left: none;
    .dropdown-item {
      background-color: #ffffff;
      color: var(--color-bg-navbar);
      &.active {
        background-color: var(--color-bg-navbar);
        color: #ffffff;
      }
      &:hover {
        background-color: var(--color-bg-navbar);
        color: #ffffff;
      }
    }
  }
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  //--bs-navbar-active-color
  //color: var(--color-link-active);
}

.dropdown-menu.show {
  --bs-dropdown-bg: var(--color-bg-navbar);
  --bs-dropdown-border-radius: 0;
  --bs-dropdown-padding-y: 0;
  --bs-dropdown-item-padding-y: 0.8rem;
  --bs-dropdown-spacer: 0;
}

.card {
  --bs-card-bg: inherit;
  --bs-border-color-translucent: var(--color-text);
  p:last-child {
    margin-bottom: 0;
  }
}

.img-fluid {
  // add width 100% to img-fluid class (todo: keep an eye on image quality)
  width: 100%;
}
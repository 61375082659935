#theme-picker {

  position: absolute;
  z-index: 100;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 15px;

  button {
    background-color: transparent;
    border-right: dashed 1px rgba(255, 255, 255, 0.15);
    border-left: dashed 1px rgba(255, 255, 255, 0.15);
    width: 100%;
    height: 100%;
    font-size: 18px;
    border-radius: 0;
    &:active, &:focus, &:focus-visible {
      border-color: transparent;
      border-right: dashed 1px rgba(255, 255, 255, 0.15);
    }
    i {
      animation: fadeIn 2s;
      color: white;
      mix-blend-mode: screen;
      &:before {
        // force sun icon if default (light) or checked light (picker)
        content: '\f185';
      }
    }
    .btn-sm {
      border-radius: 0;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  .dropdown-menu-theme {
    display: none;
    min-width: auto;
    color: white;
    background-color: var(--color-bg-navbar);
    border: none;

    &.show {
      display: block;
    }

    li {
      font-size: 18px;
      position: relative;
      display: flex;
      padding: 0;
      margin: 0;
      &.show {
        display: block;
      }
      &:has(input:checked) {
        label {
          border-color: transparent;
          color: var(--color-header-link-active);
          &:hover {
            color: white;
            background-color: var(--color-header-link-active);
          }
        }
      }
      label {
        margin: 0;
        border-radius: 0;
        font-size: inherit;
        width: 100%;
        color: white;
        &:hover {
          color: white;
          background-color: var(--color-header-link-active);
          i {
            // or maybe black
            color: inherit;
          }
        }
      }
      input.btn-sm {
        border-radius: 0;
        border: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        &:checked {
          border: none;
        }
      }
      &:last-of-type {
        label {
          &:hover {
            // todo: no border on dark mode
            border-bottom: solid 1px #ffffff;
          }
        }
      }
    }
  }
}

.tt {
  display: none;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  background: #222;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 90%;
}
.tt-arrow {
  position: absolute;
  background: #222;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
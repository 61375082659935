
@font-face {
  // Roboto
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 600;
  src: local(''), url('../Fonts/Roboto/Roboto-VariableFont_wdth,wght.ttf') format("truetype-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  // Merienda Merienda-VariableFont_wght
  font-family: "Merienda";
  font-style: normal;
  font-weight: 500 700 800;
  src: local(''), url('../Fonts/Merienda/Merienda-VariableFont_wght.ttf') format("truetype-variations");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}


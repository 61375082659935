table.table {
  --bs-table-bg: rgba(255, 255, 255, 0.3);
  --bs-table-color-state: var(--color-text);
  --bs-border-color: var(--color-bg-navbar);
}

.table>:not(caption)>*>* {
  padding: 0.3rem 0.5rem;
}

.table thead th {
  //padding: 0.3rem 0.5rem 0.8rem 0.5rem;
  vertical-align: top;
  background-color: transparent;
  font-weight: normal;
  font-size: smaller;
}

td, th {
  font-family: var(--font-family-body), sans-serif;

  a, a:visited, a:active, a:focus {
    color: var(--bs-table-color-state);
    &:hover {
      color: var(--bs-table-color-state);
    }
  }

}
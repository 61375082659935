@import "bootstrap/dist/css/bootstrap.min.css"; // don't import scss here for performance reasons!
@import "swiper/swiper-bundle.min.css";
@import "@fancyapps/ui/dist/fancybox/fancybox.css";
@import "@fancyapps/ui/dist/carousel/carousel.css";
//@import "@fortawesome/fontawesome-free/css/all.css";

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

@import "vanilla-cookieconsent/dist/cookieconsent.css";

@import "bootstrap.override";
@import "Form.scss";

@import "Fonts.scss";
@import "Tables.scss";
@import "Swiper.scss";
@import "FancyCarousel.scss";
@import "DarkTheme";
@import "ThemeSwitcher";
@import "CookieConsent";

:root {

  --color-yellow: #ffcc00;
  --color-orange: #e39407;
  --color-orange-brown: #733c02;
  --color-blue-dark: hsl(210, 31%, 15%);

  //--color-bg-header: #86a8b7;
  --color-bg-header: #9FB3C6FF;
  --color-bg-header: hsl(215, 25%, 70%);

  // base color 1
  --base-color-body: hsl(215, 25%, 80%);
  --color-base-hue: 215;
  --color-base-saturation: 25%;
  --color-base-lightness: 80%;
  --color-var-lightness: var(--color-base-lightness);
  --color-bg-body: hsl(var(--color-base-hue), var(--color-base-saturation), --color-base-lightness);

  --color-theme: #ffffff;
  --swiper-theme-color: var(--color-theme) !important;
  --swiper-pagination-bottom: 0px !important;
  --overlay-color: var(--color-bg-header);
  --color-bg-footer: #212e31;
  --color-text-footer: rgba(255, 255, 255, 0.9);
  //--color-bg-navbar: #212e31;
  --color-bg-navbar: hsl(210, 31%, 32%);

  --color-text: #2c2c2c;
  --color-titles: hsl(210,14%,36%);
  --color-links: var(--color-titles);// blau: --color-bg-navbar
  --color-links-hover: #000000;

  --color-header-link-active: var(--color-bg-header);
  --color-header-link-hover: var(--color-bg-header);
  --color-header-link: #ffffff;

  --color-prev-next-link: var(--base-color-body);
  --color-active-link: #ffffff;
  --color-active-hover: #000000;
  --color-active-bar: var(--color-bg-header);

  --text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  --font-family-header: "Merienda"; //
  --font-family-body: "Roboto";
  --font-size-copy: 1.1rem;

  /* page preference is "dark" */
  &:has(#optionDark:checked) {
    @include dark-theme;
  }

  @media (prefers-color-scheme: dark) {
    &:has(#optionSystem:checked) {
      @include dark-theme;
    }
  }
}



html {
  --text-color-normal: #0a244d;
  --text-color-light: #8cabd9;
  overflow-x: clip; // prevent horizontal scroll with horizontal scrollTrigger animation
}
body {
  background-image: url('../Images/bg-wood-001.jpg');
  font-family: var(--font-family-body), sans-serif;
  height: 100vh;
}
* {
  &.dm-sans {
    font-family: "DM Sans", sans-serif;
  }
  &.frederica {
    font-family: "Fredericka the Great", sans-serif;
  }
  &.galada {
    font-family: "Galada", sans-serif;
  }
}

nav {
  background-color: var(--color-bg-navbar);
}

#logo-top {
  display: block;
  position: absolute;
  left: 20px;
  top: 10px;
  .logo-top {
    height: 75px;
    @include media-breakpoint-down(sm) {
      height: 60px;
    }
  }
}

.logo-zeltmiete {
  height: 80px;
  @include media-breakpoint-down(md) {
    height: 60px;
  }
}
.base-color-tint {
  background-color: hsl(var(--color-base-hue), var(--color-base-saturation), var(--color-var-lightness));
}
.color2-tint {
  background-color: hsl(var(--base-color-2-hue), var(--base-color-2-saturation), var(--base-color-2-var-lightness));
}

header {
  background-color: var(--color-bg-header);
  text-align: center;
}


p, td, ul, label {
  color: var(--color-text);
}

p, h1, h2, h3, h4, ul:not(.navbar-nav,.dropdown-menu) {
  a {
    //font-weight: bold;
  }
  a, a:visited, a:active, a:focus {
    color: var(--color-links);
    &:hover {
        color: var(--color-links-hover);
    }
  }

  // add font-family classes for testing purposes
  &.rancho {
    font-family: "Rancho";
  }
  &.ranga {
    font-family: "Ranga";
  }
}

ul:not(.navbar-nav,.dropdown-menu) {
  margin-bottom: 30px;
  padding-inline-start: 20px;
  font-size: var(--font-size-copy);
  &.two-colums {
    column-count: 2;
    @include media-breakpoint-down(md) {
      column-count: 1;
    }
    @include media-breakpoint-down(sm) {
      column-count: 2;
    }
    @include media-breakpoint-up(xs) {
      column-count: 2;
    }
  }
  li {
    hyphens: auto;
    margin-right: 5px;
  }
}

h1, h2, h3, h4, th, i:not(.fa) {
  color: var(--color-titles);
}

h1 {
  font-family: var(--font-family-header), serif;
  font-weight: 700;
  letter-spacing: -2px;
  font-size: 3.2rem;
  hyphens: auto;
  margin-bottom: 20px;
  display: inline-block;
  top: 20px;
  left: 0;
  &.smooth {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.no-hyphens {
    hyphens: none;
  }
}
h2 {
  font-family: var(--font-family-header), serif;
  font-size: 2.5rem;
  font-weight: 650;
  letter-spacing: -2.1px;

  hyphens: auto;
  margin-bottom: 18px;
}
h3 {
  font-family: var(--font-family-header), serif; // todo: fallbacks
  font-size: 1.6rem;
  font-weight: 650;
  letter-spacing: -1.5px;
  hyphens: auto;
  margin-bottom: 12px;
}
h4 {
  font-size: 1rem;
}

sup.large {
  font-size: 18px;
}

p.foot-note {
  sup.asterix {
    font-size: 16px;
    margin-left: 0.2em;
    line-height: inherit;
    top: 0;
  }
}
p {
  margin-bottom:20px;
  font-family: var(--font-family-copy), sans-serif;
  font-size: var(--font-size-copy);
}

.image-lightmode {
  display: block;
}
.image-darkmode {
  display: none;
}

.spacer {
  height: 50vh;
  position: absolute;
}
.text-weight-regular {
  font-weight: 400;
  display: block;
}
.small-print {
  font-size: 0.8rem;
}
.no-wrap {
  white-space: nowrap;
}
.medium-print {
  font-size: 1rem;
  margin-left: 5px;
  letter-spacing: normal;
}
strong {
  font-weight: 600;
  color: var(--color-text);
}
td strong, th strong {
  color: inherit;
}

.container {
  max-width: 900px;
  margin: 20px auto;
  &.header {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}

hr.section-divider {
  margin: 50px 0 0 0;
  border: 0;
  border-bottom: 1px solid var(--color-text);
}

section.img-slider-section {
  padding-top: 1px; // 1px needed. 0 will fallback to default
  padding-bottom: 20px;
  background-color: var(--base-color-body);
  .container {
    margin-top: 0;
    @include media-breakpoint-down(lg) {
      --bs-gutter-x: 4rem;
    }
  }
}

section.content-section {
  padding-top: 5px;
  padding-bottom: 5px; // 5px needed. 0 will fallback to default
  background-color: var(--base-color-body);
  &:first-of-type {
    padding-top: 40px;
  }
  &:last-of-type {
    padding-bottom: 100px;
  }
  @include media-breakpoint-down(lg) {
    .container {
      --bs-gutter-x: 4rem
    }
  }

  /* add breakpoint values if regular css (not scss) is imported*/
  @include media-breakpoint-down(md) {
    .container {
      --bs-gutter-x: 4rem
    }
  }
  .row {
    --bs-gutter-x: 4rem;
    @include media-breakpoint-down(md) {
      --bs-gutter-x: 1.6rem;
    }
  }
}

section:last-of-type {
    .container {
      padding-bottom: 100px;
    }
}

footer.content-footer {
  --color-links: var(--color-text-footer);
  a:hover {
    color: var(--color-links);
  }
  //--base-color-2-var-lightness: 40%;
  p {
    color: var(--color-text-footer);
  }
  padding: 30px 0 30px 0;
  .container.small-print {
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: row;
      flex-direction: row-reverse;
      .text-center {
        margin-right: 40px;
        text-align: unset;
      }

    }
  }
  .container.footer-end {
    margin-top: 0;
  }
}
// todo: footer text small -> larger on mobile!

/*# sourceMappingURL=main.css.map */


.page-up {
  position: fixed;
  bottom: 60px;
  right: 30px;
  height: 45px;
  width: 45px;
  display: none;
  line-height: 30px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.page-up.show {
  display: block;
  opacity: 1;
}
.button-contact {
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  border-bottom: solid 1px #ffffff;
  border-left: solid 1px #ffffff;
  border-right: solid 1px #ffffff;
  height: 30px;
  width: auto;
  position: fixed;
  z-index: 200;
  right: -24px;
  padding: 3px 10px;

  top: 50%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  &:hover {
    background-color: var(--color-header-link-hover);
    color: #000000;
  }
}

.video-wrapper {
  position: relative;
}
.html5video {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
  background-color: #000000;
  pointer-events: none; // disable all click events
}
#currentFilename {
  font-size: 14px;
}

#poster-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 16 / 9;
  background: url('../Images/video-poster-IMG_9663-16zu9.jpg');
  background-size: cover;
  transition: opacity 2s ease-in-out;
  opacity: 1;
  pointer-events: none;
}
#poster-overlay.hidden {
  // important needed to override image-fade on page load
  opacity: 0 !important;
}

// animated weather icons
#weather-icon {
  position: relative;
  width: 100px;
  height: 100px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1.2s ease-in-out;

    -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .1));
    filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, .1));

    &.active {
      opacity: 1;
    }
  }
}

.fly-in {
  opacity: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  color: black;
  font-size: 30px;
  border-radius: 50%;
  background-color: yellow;
  text-align: center;
  right: -30px;
  //left: -400px;

}

// wind animation
.fly-in-element {
  width: 100%;
  position: relative;
  img {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
  }
}

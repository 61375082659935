#cc-main {
  --cc-btn-secondary-bg: var(--color-bg-header);
  --cc-btn-secondary-color: var(--color-text);
  --cc-btn-primary-color: var(--color-bg-navbar);
  // todo: lighter colors for dark mode
  .cm {
    // limit width of cookie consent
    max-width: 17rem;
    width: 17rem;
  }
  .pm__badge {
    color: var(--color-text);
  }
  .cm__title {
    margin-left: 50px;
    font-size: 1.5em;
  }
  .cookie-consent-icon {
    position: absolute;
    left: 25px;
    top: 15px;
    height: 30px;
    width: 30px;
    display: block;
    // https://fontawesome.com/v5/icons/cookie-bite?f=classic&s=solid
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path style="fill:white" d="M510.5 255.8c-70-.9-126.5-57.7-126.5-127.9-70.2 0-127-56.5-127.9-126.5-27.3-4.1-55.1 .3-79.7 12.8l-69.1 35.2a132.2 132.2 0 0 0 -57.8 57.8l-35.1 68.9a132.6 132.6 0 0 0 -12.8 81l12.1 76.3a132.5 132.5 0 0 0 37.2 73l54.8 54.8a132 132 0 0 0 72.7 37.1l76.7 12.2c27.5 4.4 55.7-.1 80.5-12.8l69.1-35.2a132.3 132.3 0 0 0 57.8-57.8l35.1-68.9c12.6-24.6 17-52.6 12.9-79.9zM176 368c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-160c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm160 128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/></svg>');
  }
}

button#button-cc-settings {
    background-color: transparent;
    border-color: white;
    color: white;
    cursor: pointer;
    &:hover {
      --bs-btn-hover-border-color: white;
      background-color: transparent;
    }
}


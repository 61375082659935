@mixin dark-theme {

  --color-scheme: dark;
  //body { background-color: hsl(317, 85%, 26%) } ;

  // base color dark
  --base-color-body: hsl(197, 16%, 18%);
  --base-color-body: #132639; // override

  // read hsl values from above
  --color-base-hue: 210;
  --color-base-saturation: 50%;
  --color-base-lightness: 15%;
  --color-var-lightness: var(--color-base-lightness);
  --color-bg-body: hsl(var(--color-base-hue), var(--color-base-saturation), --color-base-lightness);

  --color-bg-header: #193749;
  --color-bg-footer: #0c1a2a;
  --color-bg-navbar: var(--base-color-body);

  --color-text: #b6c1cc; //a5afb9
  --color-theme: #b6c1cc;
  --color-titles: hsl(210,14%,60%);

  --color-header-link-active: var(--color-orange);
  --color-header-link-hover: var(--color-orange);
  --color-header-link: #ffffff;

  --color-prev-next-link: #ffffffa8;
  --color-active-link: #ffffff;
  --color-active-hover: var(--color-orange);

  --color-yellow-moon: #cccca3;
  // override, if more contrast is needed: --color-active-bar: var(--color-orange);

  --color-links: var(--color-orange);
  --color-links-hover: #ffffff;
  --color-swiper-links: #9FB3C6FF;
  --color-swiper-links: var(--color-yellow-moon);

  h1 {
    font-weight: 600;
    letter-spacing: -2px;
  }
  h2 {
    letter-spacing: -2px;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -1px;

  }


  table.table {
    --bs-table-color-state: var(--color-bg-body);
    --bs-border-color: rgba(255, 255, 255, 0.30);
    --bs-table-bg: rgba(255, 255, 255, 0.50);
    thead {
      color: #ffffff;
      tr {
        thx {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .btn-primary {
    --bs-btn-bg: var(--color-orange);
    --bs-btn-color: #000000;
    --bs-btn-border-color: var(--color-orange);

    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #ffffff;
    --bs-btn-hover-border-color: #ffffff;
  }

  --custom-bs-form-control-border-color: var(--color-orange);
  --custom-bs-form-control-box-shadow-color: rgba(252, 244, 199, 0.5);
  --bs-form-check-bg: #000000;


  // force moon icon on button if prefers dark (system) or checked dark (picker)
  #theme-picker {
    button {
      i {
        color: var(--color-yellow-moon);
        &:before {
          content: '\f186';
        }
      }
    }
  }

  h1, h2, h3 {
    color: #ffffff;
  }

  .image-lightmode {
    display: none;
  }
  .image-darkmode {
    display: block;
    &.image-blueprint {
      opacity: 0.8;
    }
  }

  // swiper night imgages
  .swiper-slide[data-hash="alphuettli"].swiper-slide {
    background-image: url('../Images/alphuettli.nacht.png');
  }
  .swiper-slide[data-hash="zubehoer"].swiper-slide {
    background-image: url('../Images/zubehoer.nacht.png');
  }
  .swiper-slide[data-hash="eventwagen"].swiper-slide {
    background-image: url('../Images/grotto.nacht.png');
  }
  .swiper-slide[data-hash="zelte"].swiper-slide  {
    background-image: url('../Images/zelte.nacht.png');
  }

  // prev/next
  .swiper-slide.swiper-slide-prev {
    text-shadow: -12px 16px 10px rgba(0,0,0,0.5);
  }
  .swiper-slide.swiper-slide-next {
    text-shadow: 12px 16px 10px rgba(0,0,0,0.5);
  }
  .swiper-slide.swiper-slide-active {
    text-shadow: 7px 8px 3px rgba(0,0,0,0.52);
  }

  .f-carousel, .f-carousel-full-width {
    --fancy-image-bg-color: hsl(var(--color-base-hue),var(--color-base-saturation),20%);
  }

  #theme-picker {
    .dropdown-menu-theme {
      li {
        &:last-of-type {
          label {
            &:hover {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
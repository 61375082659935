/* fancy slider */
.f-carousel, .f-carousel-full-width {
  --fancy-image-bg-color: hsl(var(--color-base-hue),var(--color-base-saturation),70%);
}
.f-carousel {

  --f-carousel-slide-height: 100%;
  --f-button-hover-color: #851616;
  --f-button-hover-bg: #ffffff;
  --f-button-color: #ffffff;
  height: 125px; // todo: check if this is needed

  --unit: px;
  --number-of-slides: 5;
  --slide-spacing: 10px;
  --f-carousel-slide-width: calc((100% - 20px) / 5);
  --f-carousel-slide-width: calc((100% - calc(var(--number-of-slides) * var(--slide-spacing))) / var(--number-of-slides));
  --f-carousel-spacing: var(--slide-spacing);
  --f-carousel-dot-color: #ffffff;
}

.f-carousel__slide {
  overflow: hidden;
  aspect-ratio: 160 / 120;
  --width-fix: 3px;
  width: calc(160px + var(--f-carousel-spacing) - var(--width-fix));
  .img-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--fancy-image-bg-color);
    img {
      margin: auto;
      width: 100%;
      &.orientation-hoch {
        // margin-top: -10%;
        width: unset;
        height: 180%;
        overflow: hidden;
        margin-top: -25%;
      }
    }
  }
}

.f-carousel-full-width {
  --f-carousel-slide-width: 100%;
  --f-button-width: 58px;
  --f-button-height: 58px;

  --f-button-svg-width: 36px;
  --f-button-svg-height: 36px;
  --f-button-svg-stroke-width: 2;

  //--f-button-color: #000000;
  //--f-button-border-radius: 50%;
  //--f-button-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%), 0 3px 7px -3px rgb(0 0 0 / 30%);

  //--f-button-bg: #fff;
  //--f-button-hover-bg: #f9f9f9;
  //--f-button-active-bg: #f0f0f0;

  margin-bottom: 50px;
  height: auto;
  .f-carousel__slide {
    width: 100%;
    aspect-ratio: initial;
  }
}


.f-carousel__slide {
  // if needed slightly fade out slides, not fully visible (in combination with .is-selected rule!) with: opacity: 0.5 !important;
  border: var(--fancy-image-bg-color);
}
/*.f-carousel__slide.is-selected {
  border: solid 1px rgb(236, 174, 59);
  opacity: 1 !important;
}*/

.f-button {
  svg {
    //color: var(--f-button-color);
  }
}

.fancybox__caption {
  p, strong {
    color: #ffffff;
  }
}
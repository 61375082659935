:root {
  --color-placeholder: #a8b7c0;
  --color-placeholder-light: #bbbcc9;
  --color-red: red;

  // light mode. see Variables.scss for dark mode
  --custom-bs-form-control-border-color: var(--color-orange);
  --custom-bs-form-control-box-shadow-color: rgba(255, 220, 134, 0.63);

}

#content.contact-form {
  background-color: hsl(192, 23%, 90%);
  border: none;
}
#content.contact-form .container {
  max-width: 600px;
}

.form-group {
  margin-bottom: 1rem;
}
label {
  margin-bottom: 0.3rem;
  white-space: nowrap;
  display: flex;
}
.label-info {
  font-weight: normal;
}
.charcounter {
  margin-left: 12px;
}
.current, .maximum {
  font-weight: bold;
}

.field-info {
  margin-top: 3px;
  font-size: small;
}
.validation-error {
  color: var(--color-red);
}
.box-success {
  background-color: #2e5d05;
  color: white;
  padding: 10px 15px;
}
.box-error {
  border: solid 1px var(--color-red);
  color: var(--color-red);
  padding: 10px 15px;
  background-color: #fffdc8;
}

input::placeholder { /* Chrome/Opera/Safari */
  color: var(--color-placeholder-light);
  color: #204796;
}
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--color-placeholder);
}
input::-moz-placeholder { /* Firefox 19+ */
  color: var(--color-placeholder);
}
input:-ms-input-placeholder { /* IE 10+ */
  color: var(--color-placeholder);
}
input:-moz-placeholder { /* Firefox 18- */
  color: var(--color-placeholder);
}

.url {
  // url ist honeypot, todo: hide again
  width: 1px;
  height: 1px;
  border: none;
  padding: 0;
  display: none;
}
ul.error-list {
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
}

form {
  // todo: move other selectors to form namespace too

  .zwingend { font-weight:bold }
  label:after { content: ' (optional)'; color: hsl(192, 23%, 50%); font-size: 16px; margin-top: -2px ; margin-left: 5px }
  label.zwingend:after { content: ''; }
}

/** parsley styles : todo: change to justValidate markup*/

.form-group.parsley-success > input,
.form-group.parsley-success > select.parsley-success,
.form-group.parsley-success > textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #468847;
}

.form-group:has(.field-error){
  label {
    color: #B94A48;
    @mixin dark-theme {
      color: #fffdc8;
    }
  }
  input, select, textarea {
    color: #B94A48;
    background-color: #fffdc8;
    border: 1px solid #B94A48;
  }
}

.field-error-message {
  margin: 4px 0;
  color: #B94A48;
  @mixin dark-theme {
    color: #fffdc8;
  }
}

.form-errors-list {
  margin: 6px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  color: #B94A48;

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
}

.form-errors-list.filled {
  opacity: 1;
}

.request16826-65590 {
  display: none;
}

.form-control {
  &:focus {
    border-color: var(--custom-bs-form-control-border-color);
    box-shadow: 0 0 0 0.2rem var(--custom-bs-form-control-border-color);
  }
}
.form-check {
  .form-check-input {
    &:checked {
      background-color: var(--custom-bs-form-control-border-color);
      border-color: var(--custom-bs-form-control-border-color);
    }
    &:focus {
      border-color: var(--custom-bs-form-control-border-color);
      box-shadow: 0 0 0 0.1rem var(--custom-bs-form-control-border-color);
    }
  }
  @include media-breakpoint-down(md) {
    padding-left: 3rem;
    min-height: 3rem;
    display: flex;
    align-items: center;
    .form-check-input {
      width: 2em;
      height: 2em;
      margin-left: -3rem;
      margin-right: 1rem;
    }
    label {
      align-items: center;
      margin-top: unset;
      margin-bottom: unset;
    }
  }

}

.form-select {
    &:focus {
      border-color: var(--custom-bs-form-control-border-color);
      box-shadow: 0 0 0 0.2rem var(--custom-bs-form-control-border-color);
    }
}